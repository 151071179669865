/* Some CSS properties are overridden by the CMS (per site), see /components/layout/index.js */

:root {
  /* Palette */
  --primary: #cdf4c6;
  --secondary: #005e49;
  --tertiary: #f6f2eb;
  --background: #f6f2eb;
  --link-normal: #756452;
  --link-hover: #390020;
  --link-visited: #7C2E77;
  --shadow-button: url(/images/content/ppillerkollen_CTA_skugga_cb0fb5e730.png);
  --shadow-ball: url(/images/content/ppillerkollen_boll_f5a95c2926.png);
  --shadow-line: url(/images/content/ppillerkollen_pixelated_border_bf6463aa09.svg);
}